document.addEventListener('DOMContentLoaded', function () {
    const radios = document.querySelectorAll('input[name="slider"]');
    const contents = document.querySelectorAll('.slider-content');
    const progress = document.querySelector('.progress');
    const dynamicItems = document.querySelector('.dynamic-items');
    const dynamicItemsMobile = document.querySelector('.dynamic-items-mobile');
    const prevButton = document.getElementById('prev');
    const nextButton = document.getElementById('next');
    const prevButtonMobile = document.getElementById('prev-mobile');
    const nextButtonMobile = document.getElementById('next-mobile');

    let currentIndex = 0;

    function triggerTransition() {
        dynamicItems.classList.add('transition-effect');
        dynamicItemsMobile.classList.add('transition-effect');

        setTimeout(() => {
            dynamicItems.classList.remove('transition-effect');
            dynamicItemsMobile.classList.remove('transition-effect');
        }, 800);
    }

    function showContent(content) {
        content.style.display = 'flex';
        content.style.maxHeight = content.scrollHeight + '1px';
        content.style.opacity = '1';
    }

    function hideContent(content) {
        content.style.maxHeight = '0';
        content.style.opacity = '0.15';
        content.style.display = 'none';
    }

    function updateSlider(index) {
        radios[index].checked = true;
        progress.style.width = `${(index + 1) / radios.length * 100}%`;

        contents.forEach(content => hideContent(content));

        const correspondingContent = document.querySelector(`#content-${index + 1}`);
        showContent(correspondingContent);
    }

    prevButton.addEventListener('click', function () {
        triggerTransition();
        currentIndex = (currentIndex > 0) ? currentIndex - 1 : radios.length - 1;
        updateSlider(currentIndex);
    });

    nextButton.addEventListener('click', function () {
        triggerTransition();
        currentIndex = (currentIndex + 1) % radios.length;
        updateSlider(currentIndex);
    });

    prevButtonMobile.addEventListener('click', function () {
        triggerTransition();
        currentIndex = (currentIndex > 0) ? currentIndex - 1 : radios.length - 1;
        updateSlider(currentIndex);
    });

    nextButtonMobile.addEventListener('click', function () {
        triggerTransition();
        currentIndex = (currentIndex + 1) % radios.length;
        updateSlider(currentIndex);
    });

    radios.forEach((radio, index) => {
        radio.addEventListener('change', function () {
            currentIndex = index;
            updateSlider(currentIndex);
        });
    });

    updateSlider(currentIndex);
});
